<template>
  <div class="setpassword content_getresponse !bg-[#F1F2F7]">
    <div class="setpassowrd_inner container !rounded-[16px] !max-w-[30rem] ">

      <div class=" flex flex-col justify-center items-center gap-y-3 ">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="33" viewBox="0 0 34 33" fill="none">
          <path
            d="M32.9529 0.297483C33.3494 0.694106 33.3494 1.33716 32.9529 1.73378L29.5646 5.12208C31.7595 7.93613 31.8617 11.8528 29.8715 14.7696C29.5871 15.1863 29.2601 15.5826 28.8904 15.9524L28.281 16.5617L28.2761 16.5665L27.6491 17.1938C26.8161 18.0266 25.4657 18.0266 24.6329 17.1938L16.0564 8.61727C15.2235 7.78437 15.2235 6.43399 16.0564 5.60109L17.2978 4.35977C20.2557 1.40169 24.9122 1.17702 28.1283 3.68574L31.5164 0.297483C31.9131 -0.0991609 32.5562 -0.0991609 32.9529 0.297483ZM27.4417 5.78381C25.3339 3.68759 22.0883 3.42871 19.6996 5.00711C19.3583 5.2326 19.0345 5.49559 18.7341 5.79607L18.1247 6.40545C17.736 6.79411 17.736 7.42425 18.1247 7.81291L25.4373 15.1254C25.8248 15.5131 26.4529 15.5141 26.8417 15.1283L26.8447 15.1254L27.4541 14.5161C29.8579 12.1122 29.8619 8.2175 27.4665 5.80855L27.4539 5.7963L27.4417 5.78381Z"
            fill="#2560D7"/>
          <path
            d="M15.6867 13.5005C16.0833 13.8971 16.0833 14.5402 15.6867 14.9368L12.3422 18.2811L14.9684 20.9074L18.3128 17.5629C18.7095 17.1664 19.3526 17.1664 19.7493 17.5629C20.1458 17.9596 20.1458 18.6027 19.7493 18.9994L16.4047 22.3437L17.3118 23.2508C18.4936 24.4326 18.4936 26.3489 17.3118 27.5309L16.7023 28.1403C13.7443 31.0982 9.08786 31.3228 5.87179 28.8142L2.48349 32.2026C2.08687 32.5991 1.44382 32.5991 1.04719 32.2026C0.650549 31.8059 0.650549 31.1628 1.04719 30.7661L4.43551 27.3779C1.92699 24.1618 2.15173 19.5056 5.10973 16.5477L5.7191 15.9382C6.90101 14.7563 8.81726 14.7563 9.99916 15.9382L10.9059 16.8448L14.2504 13.5005C14.647 13.1038 15.29 13.1038 15.6867 13.5005ZM6.557 26.7147C8.96582 29.1118 12.8617 29.1082 15.266 26.704L15.8754 26.0946C16.264 25.7058 16.264 25.0757 15.8754 24.6871L8.56286 17.3746C8.1742 16.9858 7.54407 16.9858 7.15541 17.3746L6.54605 17.984C4.14181 20.3882 4.1381 24.2839 6.5349 26.6928C6.53863 26.6962 6.54233 26.6999 6.54601 26.7036C6.54971 26.7074 6.55336 26.7111 6.557 26.7147Z"
            fill="#2560D7"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Connect ActiveCampaign</p>
        <p class=" text-[14px] font-normal font-poppins text-[#757A8A] text-center">Enter Your API key and API URL to
          connect.</p>
      </div>

      <transition name="slide">
        <div v-if="!show_lists" class="px-8 pb-6 pt-10 flex flex-col justify-center items-center gap-y-3 ">

          <FloatingLabelInput
            id="api"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="ActiveCampaign API Key"
            placeholder="API Key"
            v-model="key"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="url"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="ActiveCampaign API Url"
            placeholder="API Url"
            v-model="url"
          >
          </FloatingLabelInput>
          <div class=" mt-3  flex gap-x-3 justify-between items-center">

            <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem]  !text-[14px] !font-semibold !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$router.push({name: 'integrations'})"
            >
              <template v-slot:label>Close</template>
            </Button>
            <Button
              id="API"
              type="button"
              class="px-[2.28rem] py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              :disabled="disable_connect"
              @click.prevent="setActiveCampaign()">
              <template v-slot:label>Connect</template>
            </Button>
          </div>
        </div>
      </transition>

      <transition name="slide">
        <form v-if="show_lists" class="basic_form clear !py-3" action="javascript:;">

          <ul v-if="lists" class="check_list">
            <li v-for="list in lists" class="checkbox">
              <input v-model="selected_lists" type="checkbox" :value="list" :id="list.id">
              <label :for="list.id">{{ list.name }}</label>
            </li>

          </ul>

          <div class="col-md-12 text-center btn_sec">
            <Button
              id="save"
              type="button"
              class="px-[2.28rem] py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              @click.prevent="saveActiveCampaignAccount()"
            >
              <template v-slot:label>Save</template>
            </Button>
          </div>
        </form>

      </transition>

    </div>
  </div>
</template>

<script>

import {activeCampaignConnectURL, activeCampaignSaveAccountURL} from '@/config/config.js'
import {mapActions, mapGetters} from 'vuex'
import http from '@/mixins/http-lib'

export default (
  {
    components: {
      FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
      Button: () => import('@/ui/ui-kit/v2/Button.vue')
    },
    data() {
      return {
        key: '',
        url: '',
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,
        expire: null,
        integration_id: null,
        reconnect: false,
        account_id: '',
        name: ''
      }
    },
    async created() {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile', 'getWorkspace'])
    },
    methods: {
      ...mapActions(['fetchWhitelabelAppSettings']),
      setActiveCampaign() {
        if ((this.key).trim() == '') {
          this.alertMessage('Please enter your ActiveCampaign API key.', 'error')
          return
        } else if ((this.url).trim() == '') {
          this.alertMessage('Please enter your ActiveCampaign Connect Url.', 'error')
          return
        }
        this.disable_connect = true

        let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
        let brand_id = this.getWorkspace && this.getWorkspace._id ? this.getWorkspace._id : localStorageWorkspace._id

        http.post(activeCampaignConnectURL, {
          key: this.key,
          url: this.url,
          brand_id: brand_id
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.lists = response.data.lists
                this.show_lists = true
                this.reconnect = response.data.reconnect
                this.account_id = response.data.account_id
                this.name = response.data.name
                this.expire = response.data.expire
                this.integration_id = response.data.integration_id
              } else {
                this.disable_connect = false
                this.alertMessage(response.data.message, 'error')
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      },

      saveActiveCampaignAccount() {
        if (this.selected_lists.length == 0) {
          this.alertMessage('Please select atleast one list.', 'error')
          return
        }

        let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
        let brand_id = this.getWorkspace && this.getWorkspace._id ? this.getWorkspace._id : localStorageWorkspace._id

        http.post(activeCampaignSaveAccountURL, {
          key: this.key,
          url: this.url,
          selected_lists: this.selected_lists,
          reconnect: this.reconnect,
          account_id: this.account_id,
          name: this.name,
          integration_id: this.integration_id,
          expire: this.expire,
          brand_id: brand_id
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
              }
            }
          },
          response => {
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      }
    }
  }
)
</script>
